.nest {
  .blog-post-link {
    &:hover {
      text-decoration: none;
    }
  }

  .blog-post-image-container {
    height: 128px;
  }

  .blog-post-content-container {
    @at-root .dark & {
      background-color: #2c2c2c;
    }
    @at-root .light & {
      background-color: #f4f4f4;
    }
  }
}
