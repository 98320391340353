$screen-xs-min-num: 0;
$screen-xs-min: $screen-xs-min-num + 0px;
// Small tablets and large smartphones (landscape view)
$screen-sm-min-num: 600;
$screen-sm-min: $screen-sm-min-num + 0px;
// Small tablets (portrait view)
$screen-md-min-num: 960;
$screen-md-min: $screen-md-min-num + 0px;
// Tablets and small desktops
$screen-lg-min-num: 1280;
$screen-lg-min: $screen-lg-min-num + 0px;
// Large tablets and desktops
$screen-xl-min-num: 1920;
$screen-xl-min: $screen-xl-min-num + 0px;

:export {
  screenXs: $screen-xs-min-num;
  screenSm: $screen-sm-min-num;
  screenMd: $screen-md-min-num;
  screenLg: $screen-lg-min-num;
  screenXl: $screen-xl-min-num;
}

@mixin min-xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

@mixin max-xs {
  @media (max-width: #{$screen-sm-min - 1px}) {
    @content;
  }
}

@mixin min-sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin max-sm {
  @media (max-width: #{$screen-md-min - 1px}) {
    @content;
  }
}

@mixin min-md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin max-md {
  @media (max-width: #{$screen-lg-min - 1px}) {
    @content;
  }
}

@mixin min-lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin max-lg {
  @media (max-width: #{$screen-xl-min - 1px}) {
    @content;
  }
}

@mixin min-xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}
