$kwezal-white: #ffffff;
$kwezal-gray: #78909c;
$kwezal-black: #000000;
$kwezal-red: #f1665a;
$kwezal-light-red: #ef9a9a;
$kwezal-green: #8bc248;
$kwezal-light-green: #aed581;
$kwezal-blue: #2196f3;
$kwezal-light-blue: #90caf9;

$gray: #777;

$facebook-color: #3b5998;
$github-color: #24292e;
$instagram-color: #e1306c;
$linkedin-color: #2867b2;
$medium-color: #000000;
$twitter-color: #00acee;

$primary-color-dark-home: $kwezal-white;
$secondary-color-dark-home: $kwezal-white;

$primary-color-light-home: $kwezal-black;
$secondary-color-light-home: $kwezal-black;

$primary-color-products: $kwezal-red;
$secondary-color-products: $kwezal-light-red;

$primary-color-friends: $kwezal-green;
$secondary-color-friends: $kwezal-light-green;

$primary-color-contact: $kwezal-blue;
$secondary-color-contact: $kwezal-light-blue;

:export {
  kwezalWhite: $kwezal-white;
  kwezalGray: $kwezal-gray;
  kwezalBlack: $kwezal-black;
  kwezalRed: $kwezal-red;
  kwezalLightRed: $kwezal-light-red;
  kwezalGreen: $kwezal-green;
  kwezalLightGreen: $kwezal-light-green;
  kwezalBlue: $kwezal-blue;
  kwezalLightBlue: $kwezal-light-blue;

  primaryColorDarkHome: $primary-color-dark-home;
  secondaryColorDarkHome: $secondary-color-dark-home;
  primaryColorLightHome: $primary-color-light-home;
  secondaryColorLightHome: $secondary-color-light-home;

  primaryColorProducts: $primary-color-products;
  secondaryColorProducts: $secondary-color-products;

  primaryColorFriends: $primary-color-friends;
  secondaryColorFriends: $secondary-color-friends;

  primaryColorContact: $primary-color-contact;
  secondaryColorContact: $secondary-color-contact;

  facebookColor: $facebook-color;
  githubColor: $github-color;
  instagramColor: $instagram-color;
  linkedInColor: $linkedin-color;
  mediumColor: $medium-color;
  twitterColor: $twitter-color;
}
