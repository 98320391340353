@import "Breakpoints.scss";

// Constants
@include max-xs {
  :root {
    --toolbar-height: 56px;
  }
}

@include min-sm {
  :root {
    --toolbar-height: 64px;
  }
}

$max-content-width: 1024px;

// Styles
.bold {
  font-weight: bold !important;
}

.text-left {
  text-align: left;
}

.justify {
  text-align: justify;
}

.no-text-transform {
  text-transform: none;
}

.lower-case {
  text-transform: lowercase;
}

.grow {
  flex-grow: 2;
}

.vertical-center-margin {
  margin: 0 auro;
}

.no-horizontal-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.toolbar-padding,
.content {
  padding-top: var(--toolbar-height);
}

.expand-icon {
  transition: transform 0.5s !important;

  &.expanded {
    transform: rotate(180deg);
  }
}

.section-tabs {
  .MuiTabs-flexContainer {
    justify-content: center !important;
  }
}

.section-header-bar {
  .link {
    transition: opacity 0.2s;
    opacity: 0.2;
  }

  &:hover .link {
    opacity: 1;
  }
}
