@import "../../style/Common.scss";
@import "../../style/Colors.scss";

#particles > canvas {
  height: 100vh !important;
  width: 100vw !important;
  position: absolute;
  z-index: 0;
}

.home {
  &.content {
    .section-tabs {
      .MuiTabs-flexContainer {
        justify-content: center !important;
      }
    }

    a.raw {
      @at-root .dark & {
        color: $primary-color-dark-home;
      }
      @at-root .light & {
        color: $primary-color-light-home;
      }

      font-weight: bold;
      transition: color 0.2s;

      &:hover,
      &:active,
      &:focus {
        color: $kwezal-gray;
      }
    }
  }
}
