.products {
  &.content {
    .product-image {
      $image-size: 3rem;
      width: $image-size;
      height: $image-size;

      background-size: cover;
      background-position: center;
    }
  }
}
