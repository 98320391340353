@import "../../../style/Colors.scss";
@import "../../../style/Breakpoints.scss";

.nest {
  .avatar-container {
    display: inline-block;
    border-radius: 50%;

    border-width: 1px;
    border-style: solid;

    margin: 0.25rem;

    @at-root .dark & {
      background-color: #222;
    }
    @at-root .light & {
      background-color: #fff;
    }

    // Triangulation https://snorpey.github.io/triangulation/
    // Blur: 5, Accuracy: 20, Point count: 15, Stroke width: 0
    .avatar {
      $avatar-size: 5rem;
      width: $avatar-size;
      height: $avatar-size;

      @include min-sm {
        $avatar-size: 7rem;
        width: $avatar-size;
        height: $avatar-size;
      }
      @include min-md {
        $avatar-size: 10rem;
        width: $avatar-size;
        height: $avatar-size;
      }

      border-radius: 50%;

      background-size: cover;
      background-position: center;
    }
  }
}
