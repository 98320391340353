@import "../style/Common.scss";
@import "../style/Colors.scss";
@import "../style/Breakpoints.scss";

// Fonts
@font-face {
  font-family: "Exo 2";
  src: url(/fonts/exo-2-regular.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "Exo 2";
  src: url(/fonts/exo-2-bold.ttf);
  font-weight: bold;
}

// Styles
body * {
  font-family: "Exo 2", sans-serif !important;
}

#app {
  overflow-x: hidden;
  height: 100vh;
}

#app-bar .toolbar-container {
  display: flex;
  justify-content: center;

  .toolbar {
    max-width: $max-content-width;
    flex: 1;

    > #toolbar-kwezal-logo {
      $size: 40px;
      width: $size;
      height: $size;

      background-image: url(/graphics/kwezal-logo-2020.png);
      background-size: cover;
      border-radius: 50%;

      position: absolute;
      left: -32px;

      $duration: 0.75s;
      transition: transform $duration, box-shadow $duration;

      &.activated {
        transform: rotate(-360deg);

        $shadow-length: 12px;
        box-shadow: 0 0 $shadow-length $kwezal-blue;

        &.perfect {
          box-shadow: 0 0 $shadow-length #fdd835;
        }
      }
    }
  }
}

@include max-sm {
  .navigation-item:not(.menu),
  nav .separator {
    display: none !important;
  }
}

@include min-md {
  .navigation-item.menu {
    display: none !important;
  }
}

nav {
  .navigation-item {
    text-transform: none !important;
    transition: border-color 0.3s, color 0.3s;
    border-radius: 0;
    border-bottom: 2px solid;
    font-size: 1.25rem;

    $border-opacity: 0.7;

    &.nest {
      @at-root .dark & {
        border-bottom-color: rgba($primary-color-dark-home, $border-opacity);
      }
      @at-root .light & {
        border-bottom-color: rgba($primary-color-light-home, $border-opacity);
      }
    }
    &.products {
      border-bottom-color: rgba($primary-color-products, $border-opacity);
    }
    &.friends {
      border-bottom-color: rgba($primary-color-friends, $border-opacity);
    }
    &.contact {
      border-bottom-color: rgba($primary-color-contact, $border-opacity);
    }

    &.selected {
      border-bottom-color: #0000 !important;

      &.nest {
        color: inherit;
      }
      &.products {
        color: $primary-color-products;
      }
      &.friends {
        color: $primary-color-friends;
      }
      &.contact {
        color: $primary-color-contact;
      }
    }
  }
}

.navigation-menu-item {
  font-weight: bold !important;

  &.products {
    color: $primary-color-products;
  }
  &.friends {
    color: $primary-color-friends;
  }
  &.contact {
    color: $primary-color-contact;
  }

  &.selected {
    color: #fff;
    opacity: 1 !important;

    &.nest {
      @at-root .dark & {
        color: #000;
        background-color: $primary-color-dark-home;
      }
      @at-root .light & {
        background-color: $primary-color-light-home;
      }
    }
    &.products {
      background-color: $primary-color-products;
    }
    &.friends {
      background-color: $primary-color-friends;
    }
    &.contact {
      background-color: $primary-color-contact;
    }
  }
}

.flag-button {
  text-shadow: 0 0 2px $gray;
}

.content {
  position: relative;
  z-index: 1;

  .readable-content-container {
    max-height: calc(100vh - var(--toolbar-height));
    overflow-y: auto;

    .centered-content-container {
      max-width: $max-content-width;
      margin: 0 auto;
    }
  }
}
